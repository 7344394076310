import { landingPageEvent } from "../utils/facebookpixel";
import { useEffect } from 'react';
import "./Home.css";
import LHome from "./LandingPage/LHome";
import LHome2 from "./LandingPage/LHome2";
import Testimonial from "./LandingPage/Testimonial";
import Work from "./LandingPage/Work";
import Work2 from "./LandingPage/Work2";
// import Facebook from "../utils/facebook";

const Home2 = () => {
  useEffect(() => {
    landingPageEvent();
  }, [])


  return (
    <div className='AppL'>
       <LHome2 />
       <Work2 />
       {/* <Testimonial /> */}
    </div>
  )
}

export default Home2