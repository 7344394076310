import React, { useState } from 'react'
import "./Content2Main.css";
import Packages from './Packages';

const Content2Main = () => {  
  return (
    <div className="main-content-holder-2">
      {/* <h3 className="main-text">පැකේජ / Packages</h3> */}
      <h3 className="main-texti">පහත ඇති පැකේජයක් තෝරාගන්න. (සහය සඳහා - 0773160677)</h3>
      <hr/>
      <Packages />
    </div>

  )
}

export default Content2Main