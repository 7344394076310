import accountIMG from "../../assets/user (1).png";
// import ChooseMeals from "../Assets/choose-image.png";
// import DeliveryMeals from "../Assets/delivery-image.png";

const Work2 = () => {
  const workInfoData = [
    {
      image: accountIMG,
      title: "Shalini Gamage",
      text: "\"මම මගේ දරුවව ගොඩක් ඉංග්‍රීසි පන්ති වලට යැව්වත් එයාගේ ඉංග්‍රීසි කතා කරන එකේ ලොකු වෙනසක් තිබ්බේ නෑ. kaddaweb ඉන්න උපදේශකවරු දරුවත් එක්ක යාලුවෙක් වගේ කතා කරා එයත් ගොඩක් කමැත්තෙන් කතා කරන්න ගත්ත. අපිට හිතා ගන්න බැරි තරම් ලොකු වෙනස් එයාගේ වෙනව මට පේනව ස්තූතියි\"",
    },
    {
      image: accountIMG,
      title: "Nilakshi Perera",
      text: "\"අපේ ගෙදර ඉංග්‍රීසි කතා කෙරෙන්නෙම නෑ මට ලොකු විශ්වාසයක් තිබුනෙ නැතත් ඒකයි මම මේක තෝර ගත්තේ ඇත්තටම මේක ගොඩක් හොදයි එයා ගොඩක් කැමැත්තෙන් මේක කරනව\"",
    },
    {
      image: accountIMG,
      title: "Shehanie Fernando",
      text: "\"වැරදුනාට කවුරුත් හිනා වෙන්නේ නැති නිසා මට දැන් බය නැතුව ඉංග්‍රීසි කතා කරල පුරුදු වෙන්න පුලුවන්. Thank u KaddaWeb ❤️\"",
    }
  ];
  return (
    <div className="work-section-wrapperL">
      <div className="work-section-topL">
        {/* <p className="primary-subheadingL">Work</p> */}
        <h1 className="primary-headingL2">KaddaWeb භාවිතා කරන්නන්ගේ අදහස්</h1>
        {/* <p className="primary-textL">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p> */}
      </div>
      <div className="work-section-bottomL">
        {workInfoData.map((data) => (
          <div className="work-section-infoL" key={data.title}>
            <div className="info-boxes-img-containerL">
              <img src={data.image} alt="" className="imL"/>
            </div>
            <div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work2;