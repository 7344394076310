import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import YoutubeEmbed from "../YoutubeEmbed";

const LHome2 = () => {

  return (
    <div className="home-containerL">
      <Navbar />
      <div className="home-banner-containerL">
        {/* <div className="home-bannerImage-containerL">
          <img className="imgL" src={BannerBackground} />
        </div> */}
        <div className="home-text-sectionL">
          <h1 className="primary-headingL3">
          KaddaWeb සමග අදම එක්වන්න
          </h1>
                    
          <Link className="secondary-buttonL" to="/SignUp">
          ගිණුමක් සාදන්න <FiArrowRight />{" "}
          </Link>

          <p className="primary-textL">
          <br></br>✅ ඔබගේ දරුවා වෙනුවෙන්ම සෑදූ සලැස්මකට අනුව පුහුණු කිරීම
          <br></br>✅ කැමති වේලාවක් සහ කැමති උපදේශකවරයෙකු තෝරා ගැනීමේ හැකියාව
          <br></br>
          <br></br>අප සමග සම්බන්ධ වූවන්ගේ අදහස් වෙබ්පිටුවේ පහත දක්වා ඇත.
          </p>

          {/* <div className="video-container">
            <YoutubeEmbed embedId="1EYLfm_1jqU"/>
          </div> */}
        </div>
        {/* <div className="home-image-sectionL">
          <img className="imgL" src={BannerImage} />
        </div> */}
      </div>
    </div>
  );
};

export default LHome2;