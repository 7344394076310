// import { signInWithEmailAndPassword } from "firebase/auth";
// import { createUserWithEmailAndPassword } from "firebase/auth";
import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import "./Packages.css";
import 'reactjs-popup/dist/index.css';
import { FaCheckCircle } from "react-icons/fa";
import { getFirestore,getDocs, collection, where, query, onSnapshot, doc, quer, addDoc, Timestamp } from "firebase/firestore";
import { auth } from "../../firebase";
import check from "../check.jpg";
import { v4 as uuidv4 } from 'uuid';
import payLogo from "../../assets/directpay_logo.png"
import YoutubeEmbed from "../YoutubeEmbed";
import { buttonClickPay } from '../../utils/facebookpixel';
// import Facebook from '../../utils/facebook';
// import emailIcon from "../email.png"
// import DatePicker from 'react-datepicker'

const Packages = () => {
    const email = 'hewadias459@gmail.com'
    const password = 'test1234@'
    let [Data, setDatta] = useState([]);
    
    const [reqpackageActivation,setreqpackageActivation]=useState(false)
    const [confipackageActivation,setconfipackageActivation]=useState(false)
    const [alrpackageActivation,setalrpackageActivation]=useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [done, setDone] = useState(false);
    const [show, setShow] = useState(false);
    const [valuee, setValuee] = useState('Time');
    const [valuee2, setValuee2] = useState('General');
    const [storedValues, setStoredValues] = useState([]);
    const [emailVerified, setEmailVerified] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error5, setError5] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [loading4, setLoading4] = useState(true);
    // const [selectedPack, setSelectedPack] = useState(0);
    const [selectedPack, setSelectedPack] = useState(null);
    let Datta = ['8.00 AM - 8.20 AM', '8.30 AM - 8.50 AM', '9.00 AM - 9.20 AM', '9.30 AM - 9.50 AM', '10.00 AM - 10.20 AM', '10.30 AM - 10.50 AM', '11.00 AM - 11.20 AM',
        '11.30 AM - 11.50 AM', '12.00 PM - 12.20 PM', '12.30 PM - 12.50 PM', '1.00 PM - 1.20 PM', '1.30 PM - 1.50 PM', '2.00 PM - 2.20 PM', '2.30 PM - 2.50 PM', '3.00 PM - 3.20 PM', '3.30 PM - 3.50 PM', '4.00 PM - 4.20 PM',
        '4.30 PM - 4.50 PM','5.00 PM - 5.20 PM','5.30 PM - 5.50 PM','6.00 PM - 6.20 PM','6.30 PM - 6.50 PM','7.00 PM - 7.20 PM','7.30 PM - 7.50 PM', '8.00 PM - 8.20 PM', '8.30 PM - 8.50 PM', '9.00 PM - 9.20 PM', '9.30 PM - 9.50 PM']
            
    let types = ['General', 'IELTS', 'INTERVIEW']
    const db = getFirestore();
    const userUID = auth?.currentUser?.uid
    const userEmail = auth?.currentUser?.email

    const DirectpayIpg = require('directpay-ipg-js')
    const CryptoJS = require("crypto-js");

    // const payload = {
    //   merchant_id: "IK13029",
    //   amount: "10.00",
    //   type: "ONE_TIME",
    //   order_id: "CP123",
    //   currency: "LKR",
    //   first_name: "Test",
    //   last_name: "Test",
    //   email: "hewadias459@gmail.com",
    // };

    

    const testFunc2 = (packType) => {
      console.log(packType)
      setconfipackageActivation(true)
    }
    
    // const sendFBdata = () => {
    //   !(function (f, b, e, v, n, t, s) {
    //     if (f.fbq) return;
    //     n = f.fbq = function () {
    //       n.callMethod
    //         ? n.callMethod.apply(n, arguments)
    //         : n.queue.push(arguments);
    //     };
    //     if (!f._fbq) f._fbq = n;
    //     n.push = n;
    //     n.loaded = !0;
    //     n.version = "2.0";
    //     n.queue = [];
    //     t = b.createElement(e);
    //     t.async = !0;
    //     t.src = v;
    //     s = b.getElementsByTagName(e)[0];
    //     s.parentNode.insertBefore(t, s);
    //   })(
    //     window,
    //     document,
    //     "script",
    //     "https://connect.facebook.net/en_US/fbevents.js"
    //   );
    //   fbq("init", "1761053274677444"); // Insert your Pixel ID here
    //   fbq("track", "Pay-Button-Clicked");

    //   return null;
    // }

    const testFunc = (amount) => {
        
        const ordId = uuidv4();

        const json_payload = {
         merchant_id: "IK13029",
         amount: amount,
         type: "ONE_TIME",
         order_id: ordId,
         currency: "LKR",
         return_url: "https://kaddaweb.com/Payment-process"
        //  first_name: "Test",
        //  last_name: "Test",
        //  email: "hewadias459@gmail.com",
       }

    
      var encode_payload = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(json_payload)));
        
      var signature = CryptoJS.HmacSHA256( encode_payload, 'f5b17ff04344e33eaea71f79a4e641b8be8bc8e205ebc715831bb4b243bb95cf');
       
        const dp = new DirectpayIpg.Init({
            signature: signature,
            dataString: encode_payload,
            stage: 'PROD',
            container: 'card_container'
          })

          dp.doInAppCheckout().then((data) => {
            console.log('client-res', JSON.stringify(data))
          }).catch((error) => {
            console.log('client-error', JSON.stringify(error))
          })

          // dp.doInContainerCheckout().then((data) => {
          //   console.log('client-res', JSON.stringify(data))
          // }).catch((error) => {
          //   console.log('client-error', JSON.stringify(error))
          // })

    }  

    const testFunc3 = () => {
      setShow(true)
    }

    
    const packActiv = async (packType) => {
      
    //   console.log('packType', packType)
      setSelectedPack(packType)
    //   console.log(selectedPack)
        //get userUID
    //   const curUser = firebase.auth().currentUser
    //   const uid = curUser.uid
    //   console.log(uid)
      //query user plan object based on UID
      try{

      
      const colRef = collection(db, 'userPlan')
    //   const querySnapshot = await getDocs(collection(db, "userPlan"), where("userUID", "==", "1235"));
      const q = query(colRef, where("userUID", "==", userUID))
    
      const querySnapshot = await getDocs(q)
      const temporaryArr = [];
        querySnapshot.forEach((doc) => {
            temporaryArr.push(doc.data());
        });
        // setStoredValues(temporaryArr);
        console.log(temporaryArr)

        if(temporaryArr.length !== 0){
            //user has plans already
            const status = temporaryArr[0].activStatus
            if(status === 1){
                console.log('already requested a activation')
                setreqpackageActivation(true)
            } else if(status === 2){
                //if clicked button same as already activated package schedule class otherwise load change pack msg
                console.log('you have already activated a package')
                setalrpackageActivation(true)
            } else {
                return;
            }
        } else if (temporaryArr.length === 0){
           // create new userPlan
        //    console.log('No plans so creating one')
        //    addDoc(colRef, {
        //     userUID: '2500',
        //     userName: 'samaa',
        //     activStatus: 1
        //    })
           setconfipackageActivation(true)
        }
    
    //    onSnapshot(q, (snapshot) => {
    //     let plans = []
    //     snapshot.docs.forEach((doc) => {
    //         plans.push({ ...doc.data(), id: doc.id })
    //     })
    //     console.log('our array ->', plans)
    //    })

      //based on that check if user already has a plan activated
      //If so tell user to contact us to change the plan or say that he has already requested a plan activation
      //If no plans has been activated by the user then create new userPlan DB object and set activstatus to 1
      //also at end of function notify the user based on the result
      } catch (error){
        console.log(error)
        return
      }}

      const createPlan = async () => {
        
        setconfipackageActivation(false)

        const colRef = collection(db, 'userPlan')
        var myTimestamp = Timestamp.fromDate(new Date());

        addDoc(colRef, {
            userUID: userUID,
            userName: userEmail,
            activStatus: 2,
            // activStatus: 2,
            firReqDate: myTimestamp,
            packType: selectedPack,
           })
        
        // setreqpackageActivation(true)
        setShow(true)   
      }

      const count = async (pack) => {
        
        
         
        const colRef = collection(db, 'buttonClicks')
        var myTimestamp = Timestamp.fromDate(new Date());

        addDoc(colRef, {
            userUID: userUID,
            userName: userEmail,
            clickedTime: myTimestamp,
            packType: pack,
            paymentType: 'online'
           })
        
        // setreqpackageActivation(true)
        
      }

      const count2 = async (pack) => {
        
        

        const colRef = collection(db, 'buttonClicks')
        var myTimestamp = Timestamp.fromDate(new Date());

        addDoc(colRef, {
            userUID: userUID,
            userName: userEmail,
            clickedTime: myTimestamp,
            packType: pack,
            paymentType: 'bank account'
           })
        
        // setreqpackageActivation(true)
        
      }

      const chechEmailVerif = async () => {
        setLoading2(true)
        if(auth.currentUser.emailVerified){
            setEmailVerified(true)
            setLoading2(false)
        } else {
            setLoading2(false)
            return
        }
     }

     const getTimeSlots = (date) => {
        if(date){
        const today = new Date()
        // console.log('today->', today)
        // console.log('selectedDate ->', selectedDate)
        if(date.toISOString().split('T')[0] === today.toISOString().split('T')[0]){
        const hour = today.getHours() + 1
        console.log(hour)
        const slot = (hour - 8)*2
        const newArrayy = Datta.slice(slot)
        console.log(newArrayy)
        if(newArrayy.length !== 0){
          setDatta(newArrayy)
        } else if (newArrayy.length === 0){
            let array = ['No Time Slots Available']
            setDatta(array)
        }
        } else {
            setDatta(Datta)
        }
        } else {
            return
        }
    }

    const handleChange = (e) => {
        setValuee(e.target.value);
      };

      const handleChange2 = (e) => {
        setValuee2(e.target.value);
      };

      const func1 = () => {
        setError4(false)
        setError5(false)
        if(selectedDate){
          console.log(selectedDate)
          if(Data[0] !== 'No Time Slots Available'){
              scheduleaClass()
              // console.log(valuee);
          } else {
            setError5(true)
          }
        } else {
          setError4(true)
        }
      // console.log(valuee);
      }

      const getTimeSlots1 = (date) => {
        if(date){
        const today = new Date()
        // console.log('today->', today)
        // console.log('selectedDate ->', selectedDate)
        if(date.toISOString().split('T')[0] === today.toISOString().split('T')[0]){
        const hour = today.getHours() + 1
        console.log(hour)
        const slot = (hour - 8)*2
        const newArrayy = Datta.slice(slot)
        console.log(newArrayy)
        if(newArrayy.length !== 0){
          setDatta(newArrayy)
          setValuee(newArrayy[0])
        } else if (newArrayy.length === 0){
            let array = ['No Time Slots Available']
            let array2 = ['8.00 AM - 8.20 AM', '8.30 AM - 8.50 AM', '9.00 AM - 9.20 AM']
            setDatta(array)
            setValuee(array2[0])
        }
        } else {
            setDatta(Datta)
        }
        } else {
            return
        }
    }

     const scheduleaClass = async () => {
        
        try{
            setLoading4(true)
            setError2(false)
            setError3(false)
            const colRef = collection(db, 'scheduleClassRequest')
            const q = query(colRef, where("userUID", "==", userUID))
            const querySnapshot = await getDocs(q)
            const temporaryArr = [];
            querySnapshot.forEach((doc) => {
                temporaryArr.push(doc.data());
            });
            if(temporaryArr.length !== 0){
                setError2(true)
                setLoading4(false)
                return
            }else if(temporaryArr.length === 0){
                const colRef = collection(db, 'scheduleClassRequest')
                var myTimestamp = Timestamp.fromDate(new Date());

                addDoc(colRef, {
                    userUID: userUID,
                    userName: userEmail,
                    requestCreatedTime: myTimestamp,
                    classDate: selectedDate,
                    classTime: valuee,
                    classType: valuee2
                   })
                
                setDone(true)
                setLoading4(false)
            } else {
                setError3(true)
                setLoading4(false)
                return
            }
        }catch(e){
            console.log(e)
            console.log(valuee)
            console.log(valuee2)
            setError3(true)
            setLoading4(false)
        }
    }

     useEffect(() => {
        chechEmailVerif()
        getTimeSlots1(new Date());
      }, []);
    
      const openTab1 = (url) => {
        window.open(url,"_self");
      };

    const [classes]=useState([
        // {
        //     packName: 'Package',
        //     title:'Rs.2700',
        //     option1:'2 Theory Sessions',
        //     option2:'',
        //     option3:'2 Speaking Sessions',
        //     backColour: '#4ecdc4',
        //     packType: 3,
        //     link: 'https://gateway.directpay.lk/link/09ab06d219',
        //     amount: "2700.00"
        // }

        {
          packName: 'New User Package',
          title:'Rs.450',
          option1:'පන්ති 1 ක් / 1 Class',
          option2:'Duration: 20 minutes',
          option3:'පන්තියක වේලාව විනඩි 20යි',
          backColour: '#4ecdc4',
          packType: 3,
          link: 'https://gateway.directpay.lk/link/09ab06d219',
          amount: "450.00"
        },
        
        {
            packName: 'Package 1',
            title:'Rs.2700',
            option1:'පන්ති 6 ක් / 6 Classes',
            option2:'Duration: 20 minutes',
            option3:'පන්තියක වේලාව විනඩි 20යි',
            backColour: '#96cd4e',
            packType: 1,
            link: 'https://gateway.directpay.lk/link/13e3cb3362',
            amount: "2700.00"
        },

        {
            packName: 'Package 2',
            title:'Rs.3900',
            option1:'පන්ති 9 ක් / 9 Classes',
            option2:'Duration: 20 minutes',
            option3:'පන්තියක වේලාව විනඩි 20යි',
            backColour: '#8b5dd9',
            packType: 2,
            link: 'https://gateway.directpay.lk/link/4129ace70f',
            amount: "3900.00"
        }

        // {
        //     packName: '',
        //     title:'',
        //     option1:'',
        //     option2:'',
        //     option3:'',
        //     backColour: '#ffffff',
        //     packType: 3
        // }

        // {
        //     packName: 'Pro',
        //     title:'Rs.5600',
        //     option1:'පන්ති 15 ක් / 15 Classes',
        //     option2:'Duration: 20 minutes',
        //     option3:'පන්තියක වේලාව විනඩි 20යි',
        //     backColour: '#8b5dd9',
        //     packType: 3
        // }
    ])
  return (
    
    
   
    <div className='packagePage'>
      
        {/* {loading2 ? null : (!emailVerified ? <div className='msgContaner-1'>
                 <div className='image-1'>
                  <img src={emailIcon} className='img-1'/>  
                 </div>       
                 <div className='textContainer-1'>
                  <h3 className='msg-1'>We have send an email to {userEmail}. Please verify your email</h3>
                 </div>
                </div> : null)} */}
         {show ? <h3 className='tex-3'>පහත ගිණුමට මුදල් බැර කර රිසිට් පතෙහි ඡායා රූපයක් 0773160677 අංකයට WhatsApp කරන්න හෝ අමතන්න 0773160677</h3> : null}
         {show ? <h3 className='tex-3'>
            Sampath Bank
            <br />

            <br />
            S S H AMBEPITIYA
            <br />

            <br />
            ගිණුම් අංකය - 109457961826
            <br />

            <br />
            Branch - Kohuwala (කොහුවල ශාඛාව)
            </h3> : null}
            {show ? <div className='btnalign'>
              <button className='btnn' onClick={() => setShow(false)}>Back</button>
            </div> : null}       
         {/* {show ? <h3 className='tex-3'>සාර්ථක‍යි. ඔබගේ පළමු පන්තිය සදහා ඔබට පහසු වේලාවක් පහලින් තෝරාගන්න. ගෙවීම පළමු පන්තියෙන් පසු සිදු කරන්න. සහාය සදහා අමතන්න - 0773160677</h3> : null}        */}
{/* {show ? <div className='msgContainer-3'>
{done ? <h3 className='tex-4'>You have succesfully requested a schedule we will approve it within few minutes. Thank you</h3> : null}
                 {!done ? <h3 className='tex-3'>පන්ති වේලාව තෝරන්න / Let's schedule a session</h3> : null}
                 {!done ? <div className='input-Container'>
                 
                  
                  <DatePicker selected={selectedDate} className='inpu1' onChange={date=>{setSelectedDate(date); getTimeSlots(date)}} placeholderText='Click to add date' 
                  dateFormat='yyyy/MM/dd'
                  minDate={new Date()}
                  />
    
                 
            
                 {error4 ? <h4 className='error-tex'>select a valid date</h4> : null}
                 
                 <select className='inpu2' onChange={handleChange} onClick={handleChange} value={valuee}>{
    Data.map( (x,y) => 
      <option key={y}>{x}</option> )
  }</select>

<select className='inpu2' onChange={handleChange2} value={valuee2} onClick={handleChange2}>{
    types.map( (x,y) => 
      <option key={y}>{x}</option> )
  }</select>
                
                {error5 ? <h4 className='error-tex'>select a valid time</h4> : null}
                {error3 ? <h4 className='error-tex'>error try again</h4> : null}
                {error2 ? <h4 className='error-tex'>You have already requested to schedule a class</h4> : null}
                <button className='btn' onClick={()=>func1()}>තෝරන්න / Schedule</button>
                </div> : null}</div> : null}         */}
        
        { reqpackageActivation ? (<div>
                <div className='packActivation'>
                    <div className='image'>
                        <img src={check}/>
                    </div>
                    <div className="packAct-cont">
                        <h3 className='text-activation'>ඔබ පැකේජයක් සක්‍රිය කිරීම සඳහා ඉල්ලුම් කර ඇත. ඒ සඳහා අපගේ නියෝජිතයෙක් ඉදිරියේදී ඔබ හා සම්බන්ධ වනු ඇත. / You have requested a package activation, We will contact you to proceed !</h3>
                    </div>
                </div>
            </div>) : null}

        { confipackageActivation ? (<div>
                <div className='packActivation'>
                    {/* <div className='image'>
                        <img src={check}/>
                    </div> */}
                    <div className="packAct-cont">
                        <h3 className='text-activation-2'>ඔබට මෙම පැකේජය සක්‍රිය කිරීමට අවශ්‍යද ? / Do you want to activate the selected package ?</h3>
                    </div>

                    <div className='buttonHolder2'>
                     <button className ='btn3' onClick={() => createPlan()}>තහවරු කරන්න / Activate</button>
                    </div>
                </div>
            </div>) : null}

        { alrpackageActivation ? (<div>
                <div className='packActivation'>
                    {/* <div className='image'>
                        <img src={check}/>
                    </div> */}
                    <div className="packAct-cont">
                        {/* <h3 className='text-activation'>You already have an activated package do you want to change it ?</h3> */}
                        <h3 className='text-activation'>ඔබගේ පැකේජය වෙනස් කිරීම සදහා අමතන්න - 0773160677 / To change your current package contact us - 0773160677</h3>
                    </div>
                    {/* <div className='buttonHolder2'>
                     <button className ='btn3' >Proceed</button>
                    </div> */}
                </div>
            </div>) : null}    

        {(reqpackageActivation || alrpackageActivation || confipackageActivation || show) ? (null):<section>
            
            {/* <h3 className='text-activation-3'>🔴 ගෙවීම පළමු පන්තියෙන් පසු 🔴</h3> */}
            
            {/* <div className='reviewContainer'>
            <p>" මට හම්බවුන සර් එක්ක මම යාලුවෙක් එක්ක වගේ හැමදාම ඉංග්‍රීසි කතා කරා. විනඩි 20ක් හින්ද නියමයි. දවසින් දවස දන්නෙම නැතුව මගේ ඉංග්‍රීසි හොඳ වෙන්න ගත්ත. KaddaWeb කියන්නෙ මම කරපු හොඳම ආයෝජනයක් "</p>
             <div className='reviewContainertop'>
              <h2>- Vimukthi Jayasinghe -</h2>
              
             </div>
             
            </div> */}
            <div className="container2">
                <div className="cards2">
                    {
                        classes.map((card, i)=>(

                    <div key={i} className="card2" style={{backgroundColor: card.backColour}}>
                        <div className='holder2'>  
                          <h3>{card.packName}</h3>
                        </div>  
                        <div className='holder2'>  
                          <h1>{card.title}</h1>
                        </div>
                        
                        <div className='holder1'>
                          <div className='holder3'>
                          {card.option1 ? (<FaCheckCircle className='iconn'/>) : null}
                          <h3>{card.option1}</h3>
                          </div>

                          <div className='holder3'>
                          {card.option3 ? (<FaCheckCircle className='iconn'/>) : null}
                          <h3>{card.option3}</h3>
                          </div>
                          
                          <div className='holder3'>
                          {card.option2 ? (<FaCheckCircle className='iconn'/>) : null}
                          <h3>{card.option2}</h3>
                          </div>

                          {/* <div className='holder3'>
                          <FaCheckCircle className='iconn'/>
                          <h3>{card.option2}</h3>
                          </div>

                          <div className='holder3'>
                          <FaCheckCircle className='iconn'/>
                          <h3>{card.option2}</h3>
                          </div>

                          <div className='holder3'>
                          <FaCheckCircle className='iconn'/>
                          <h3>{card.option2}</h3>
                          </div> */}
                          
                        </div>
                        
                        {card.option1 ? (<Popup
    trigger={<div className='buttonHolder'>
      <button className ='btn2'>සක්‍රිය කරන්න / Activate</button>
      {/* <button className ='btn2' onClick={() => setconfipackageActivation(true)}>Activate</button> */}
     </div>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header">{card.packName} ({card.title})</div>
        {/* <div className="content">
          {' '}
          ඔබට මෙම පැකේජය සක්‍රිය කිරීමට අවශ්‍යද ? / Do you want to activate the selected package ?
        </div> */}
        <div className="content">
          {' '}
          ඔබට මෙම පැකේජයට ගෙවීමට අවශ්‍යද ? / Do you want to pay for the selected package ?
        </div>
        {/* <div className="actions">
        <button
            className="popupbutton1"
            onClick={() => {
              count(card.packType);
              testFunc(card.amount)}}
          >
            Pay Online
          </button>

          <button
            className="popupbutton2"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            No
          </button>
        </div> */}

        <div className="actions2">
        <button
            className="popupbutton3"
            onClick={() => {
              count(card.packType);
              testFunc(card.amount);
              buttonClickPay()}}
          >
            Pay Online
          </button>

          <h3 className="ortext">- OR -</h3>
          <button
            className="popupbutton3"
            onClick={() => {
              count2(card.packType);
              testFunc3();
              buttonClickPay()}}
          >
            Deposite to bank account
          </button>

          <button
            className="popupbutton2"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Close
          </button>
        </div>
        <div className="paymentlogoholder">
         <h4>Secured with</h4>
         <img src={payLogo} className='paymentLogo'/>
        </div>
      </div>
    )}
  </Popup>) : null}
                        
                    </div>
                        ))
                    }
                </div>
                {/* <div className='centerText'>
                 <h4>අමතන්න / Call Us - 0773160677</h4>
                </div> */}
                <h3>Thank you @Hera's Diary for mentioning us ❤️</h3>
                <div className='videocontainer'>
                <YoutubeEmbed embedId="QXRv_FyF5Y8" startTime={566}/>
                </div>
            </div>
        </section>}
    </div>
    
    
  )
}

export default Packages