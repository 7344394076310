import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFirestore, collection, addDoc, Timestamp} from "firebase/firestore";
import Input from "./Input";
import Button from "./Button";
import {auth} from "../firebase";
import { useDispatch } from "react-redux";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import Icon from "./Icon";
import "./SignUp.css";
import check from "../components/kad-logo5.png"
import {
  setUserLoginDetails
} from "../features/user/userSlice";
import { buttonClickSignUp } from "../utils/facebookpixel";
// import Facebook from "../utils/facebook";

function SignUp() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [msg, setMsg] = useState("");
  // const [error2, setError2] = useState(false);
  const db = getFirestore();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FacebookBackground = "linear-gradient(to right, #0546a0 0%, #663fb6 100%)";
  const InstagramBackground = "linear-gradient(to right, #a12ac4 0%, #ed586c 40%, #f0a853 100%)";
  const TwitterBackground = "linear-gradient(to right, #56c1e1 0%, #35a9ce 50%)";
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const isValidPhone = /^(?:7|0|(?:\+94))[0-9]{9,10}$/g;

  const setUser = (user) => {
    dispatch(
      setUserLoginDetails({
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
      })
    );
  };

  // const sendFBdata = () => {
  //   !(function (f, b, e, v, n, t, s) {
  //     if (f.fbq) return;
  //     n = f.fbq = function () {
  //       n.callMethod
  //         ? n.callMethod.apply(n, arguments)
  //         : n.queue.push(arguments);
  //     };
  //     if (!f._fbq) f._fbq = n;
  //     n.push = n;
  //     n.loaded = !0;
  //     n.version = "2.0";
  //     n.queue = [];
  //     t = b.createElement(e);
  //     t.async = !0;
  //     t.src = v;
  //     s = b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t, s);
  //   })(
  //     window,
  //     document,
  //     "script",
  //     "https://connect.facebook.net/en_US/fbevents.js"
  //   );
  //   fbq("init", "1761053274677444"); // Insert your Pixel ID here
  //   fbq("track", "Signup");

  //   return null;
  // }

  const createUser = async (uid) => {

    const colRef = collection(db, 'realUser')
    var myTimestamp = Timestamp.fromDate(new Date());
     
    addDoc(colRef, {
        userUID: uid,
        Email: email,
        Name: name,
        phoneNo: phone,
        createdTime: myTimestamp,
       })

       buttonClickSignUp();
  }
  
  const signUpUser = async () => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        sendEmailVerification(userCredential.user)
        createUser(userCredential.user.uid)
        setUser(userCredential.user)
        
      })
      setLoading(false)
      console.log(user)
      navigate("/Packages");  
    } catch(error) {
      console.log(error)
      setLoading(false);
      setError6(true);
      if(error.code === 'auth/email-already-in-use'){
        console.log('email already there');
        setError2(true);
      }
    }
  }

  // function validatePassword(pw) {

  //   return /[A-Z]/       .test(pw) &&
  //          /[a-z]/       .test(pw) &&
  //          /[0-9]/       .test(pw) &&
  //          /[^A-Za-z0-9]/.test(pw) &&
  //          pw.length > 4;

  // }

  // const validatePass = (pw) => {
  //   return /[A-Z]/       .test(pw) &&
  //          /[a-z]/       .test(pw) &&
  //          /[0-9]/       .test(pw) &&
  //          /[^A-Za-z0-9]/.test(pw) &&
  //          pw.length > 4;
  // }
  const validatePass = () => {
    // setError2(false);
    if(password.length >= 6 && confpassword.length >= 6){
      if(password === confpassword){
        console.log('success');
        signUpUser();
      } else {
        setLoading(false);
        console.log('password dont match');
        setError5(true);
      }
    }else{
      setLoading(false);
      setError4(true);
      console.log('Invalid Password - less length');
      return
    }
  }

  const validatePhone = () => {
    // setError2(false);
    if(phone && phone.match(isValidPhone)){
      console.log('Valid phone');
      validatePass();
      // const result = validatePassword(password);
      // console.log(result)
      // signUpUser();
    }else{
      setLoading(false);
      setError3(true);
      console.log('Invalid phone');
      return
    }
  }
  
  const validateEmail = () => {
    setLoading(true);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    // setError2(false);
    if(email && email.match(isValidEmail)){
      console.log('Valid email');
      validatePhone();
    }else{
      setLoading(false);
      setError1(true);
      console.log('Invalid email');
      return
    }
  }

  return (
    <div className="body-container">
    <Maincontainer>
      <div className='imgr3'>
        <img src={check} className='imagge-3'/>
      </div>
      <WelcomeText>නව ගිණුමක් සාදන්න</WelcomeText>
      <InputContainer>
        {/* <Input type="text" placeholder="Name" /> */}
        <input type="name" placeholder="ඔබගේ නම / Name" value={name} onChange={(e) => setName(e.target.value)} />
        {/* <Input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/> */}
        <input type="email" placeholder="ඊමේල් / Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {error1 ? <div className="error-container">
         <h3 className="error1">*Invalid email</h3>
        </div> : null}
        {/* <Input type="tel" placeholder="Phone Number" /> */}
        <input type="tel" placeholder="දුරකථනය / Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
        {error3 ? <div className="error-container">
         <h3 className="error1">*Invalid phone number</h3>
        </div> : null}
        {/* <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/> */}
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <input type="password" placeholder="Confirm Password" value={confpassword} onChange={(e) => setConfPassword(e.target.value)} />
        {error4 ? <div className="error-container">
         <h3 className="error1">*Password should contain at least 6 characters</h3>
        </div> : null}
        {error5 ? <div className="error-container">
         <h3 className="error1">*Password dosen't match please try rentering</h3>
        </div> : null}
        {/* <Input type="password" placeholder="Confirm Password" /> */}
        {error6 ? <div className="error-container">
         <h3 className="error1">Error Signing up</h3>
        </div> : null}
        {error2 ? <div className="error-container">
         <h3 className="error1">Email is already in use try logging in</h3>
        </div> : null}
      </InputContainer>
      <ButtonContainer>
        {/* <Button content="Sign Up" onClick={validateEmail}/> */}
        {/* <button className="styledbutton" onClick={validateEmail}>Sign Up</button> */}
        {!loading ? <button className="styledbutton" onClick={validateEmail}>ගිණුම සාදන්න / Sign Up</button> :
        <div className="styledbutton">
          <h5>Loading..</h5>
        </div>}
      </ButtonContainer>
      {/* <NewAccount>Already have an account? Login</NewAccount> */}
      <div className="abcd">
       <h5 className="text-4">දැනටමත් ගිණුමක් තිබේද? <Link className="link-2" to="/">ගිණුමට පිවිසෙන්න</Link></h5>
      </div>
      
      
      {/* <LoginWith>or Login With</LoginWith> */}
      {/* <HorizontalRule/> */}
      {/* <IconsContainer>
        <Icon color={FacebookBackground}>
          <FaFacebookF/>
        </Icon>
        <Icon color={InstagramBackground}>
          <FaInstagram/>
        </Icon>
        <Icon color={TwitterBackground}>
          <FaTwitter/>
        </Icon>
      </IconsContainer> */}
      {/* <ForgotPassword>Forgot Password ?</ForgotPassword> */}
    </Maincontainer>
    </div>
  );
}

const Maincontainer =styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  // height: 80vh;
  width: 30vw;
  // background: rgba(255, 255, 255, 0.15);
  background: #ffffff;
  box-shadow:0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  @media only screen and (max-width:320px){
    width: 80vw;
    // height: 90vh;
    hr{
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }

  @media only screen and (min-width:360px){
    width: 80vw;
    // height: 90vh;

    h4 {
      font-size: small;
    }
  }

  @media only screen and (min-width:411px){
    width: 80vw;
    // height: 90vh;
  }

  @media only screen and (min-width:768px){
    width: 80vw;
    // height: 80vh;
  }

  @media only screen and (min-width:1024px){
    width: 70vw;
    // height: 50vh;
  }

  @media only screen and (min-width:1280px){
    width: 30vw;
    // height: 80vh;
  }
`;

const WelcomeText = styled.h2`
  // margin: 1rem 0 0.5rem 0;
  font-size: 1.2rem;
  color: #525252;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 1.5rem 0 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: blue;
`
const LoginWith = styled.h5`
    cursor: pointer;
`;

const HorizontalRule = styled.hr`
    width: 90%;
    height: 0.3rem;
    border-radius: 0.8rem;
    border: none;
    margin: 1.5rem 0 1rem 0;
    background: linear-gradient(to right, #14163c 0%, #03217b 79%);
    backdrop-filter: blur(25px);
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;

const NewAccount = styled.h5`
    cursor: pointer;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
    
`;

const ForgotPassword = styled.h4`
  cursor: pointer;
`
export default SignUp;
