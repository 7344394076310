import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import YoutubeEmbed from "../YoutubeEmbed";

const LHome = () => {
// const navigate = useNavigate();

// const goSignUp = () => {
//  navigate(/)
// }
  return (
    <div className="home-containerL">
      <Navbar />
      <div className="home-banner-containerL">
        {/* <div className="home-bannerImage-containerL">
          <img className="imgL" src={BannerBackground} />
        </div> */}
        <div className="home-text-sectionL">
          <h1 className="primary-headingL3">
          KaddaWeb සමග ඔබත් අදම එක්වන්න
          </h1>
          {/* <h1 className="primary-headingL3">
          කතා කරලම ඉංග්‍රීසි ඉගෙන ගන්න
          </h1> */}
          {/* <h1 className="primary-headingL3">
          ඉංග්‍රීසි කතා කරන්න ඉගෙන ගන්න ලංකාවෙ තියෙන හොඳම Platform එක
          </h1> */}

          {/* <h1 className="primary-headingL3">
          ඔබේ ඉංග්‍රීසි මට්ටම කුමක් වූවත් අපේ උපදේශකවරු ඔබව පුහුණු කරනවා
          </h1> */}
          {/* <h1 className="primary-headingL3">
          ඔබේ ඉංග්‍රීසි මට්ටම කුමක් වූවත් ඉතා කෙටි කාලයකදී ප්‍රායෝගිකව ඉංග්‍රීසි ඉගෙන ගන්න
          </h1> */}
          {/* <p className="primary-textL">
          එසේ නම් අපගේ 100+ ටත් වැඩි පලපුරුදු උපදේශකවරුන් සමග එක්වී නිවසේ සිටම ඉංග්‍රීසි කතා කරන්න
          </p> */}
          {/* <p className="primary-textL">
          එසේ නම් අපගේ පලපුරුදු උපදේශකවරුන් සමග එක්වී නිවසේ සිටම ඉංග්‍රීසි කතා කරන්න
          </p> */}
                    
          <Link className="secondary-buttonL" to="/SignUp">
          ගිණුමක් සාදන්න <FiArrowRight />{" "}
          </Link>

          <p className="primary-textL">
          KaddaWeb පිලිබඳ වැඩි විස්තර දැන ගැනීමට පහත ඇති වීඩියෝව නරඹන්න. අප සමග සම්බන්ධ වූවන්ගේ අදහස් වෙබ්පිටුවේ පහත දක්වා ඇත.
          </p>

          <div className="video-container">
            <YoutubeEmbed embedId="1EYLfm_1jqU"/>
          </div>
        </div>
        {/* <div className="home-image-sectionL">
          <img className="imgL" src={BannerImage} />
        </div> */}
      </div>
    </div>
  );
};

export default LHome;