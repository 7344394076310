import "./Home.css";
import { useEffect } from 'react';
import LHome from "./LandingPage/LHome";
import Testimonial from "./LandingPage/Testimonial";
import Work from "./LandingPage/Work";
import { landingPageEvent } from "../utils/facebookpixel";
// import Facebook from "../utils/facebook";


const Home = () => {
  
  useEffect(() => {
    landingPageEvent();
  }, [])

  return (
    <div className='AppL'>
       
       <LHome />
       <Work />
       <Testimonial />
    </div>
  )
}

export default Home